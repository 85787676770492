import React, { useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import useStyles from "../Login/styles";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Backdrop,
  TextField,
  MenuItem,
} from "@material-ui/core";
import globa_styles from "../../index.module.css";
import payeasy_styles from "../PayEasy/PayEasy.module.css";
import Loader from "../Loader/Loader";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";

const ConfirmVoucherPurchase = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const dataDec = dataReceiver;
  const [responseMessage, setResponseMessage] = useState("");

  // Destructure the voucherDetails from location.state (it's passed through history.push)
  const { data } = location.state || {};
  const message = data.message;

  const [open, setOpen] = React.useState(false);

  const goToMyVouchers = (e) => {
    history.push({
      pathname: "/voucherDetails",
      state: { data: data },
    });
  };

  const buyOTTVoucher = async () => {
    let payload = {
      cellNumber: data.cellNumber,
      amount: data.amount,
      transactionNumber: data.transactionNumber,
    };

    const res = await axios.post(
      baseURL + "bridgeReceiveOTTTransactionCallback.php",
      JSON.stringify(payload)
    );
    const responseData = res.data;

    console.log(responseData.status);

    if (responseData.status === "true") {
      goToMyVouchers();
    } else {
      setResponseMessage(responseData.response);
    }
  };

  if (!data) {
    return <div>This operation returned an empty result</div>;
  }

  return (
    <div className={globa_styles.container + " " + payeasy_styles.container}>
      <h1 className={payeasy_styles.heading}>Voucher Purchase Confirmation</h1>

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body1">
                  <h5
                    style={{
                      marginBottom: "10px",
                      textDecoration: "underline",
                    }}>
                    {message}
                  </h5>
                </Typography>

                {responseMessage ? (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "red",
                    }}>
                    {responseMessage}
                  </p>
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#f99d1c",
                    }}>
                    Please approve the transaction on your mobile phone to
                    continue
                  </p>
                )}

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => buyOTTVoucher()}>
                  Complete transation
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ConfirmVoucherPurchase;
