import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Backdrop,
  TextField,
  MenuItem,
} from "@material-ui/core";
import globa_styles from "../../index.module.css";
import payeasy_styles from "../PayEasy/PayEasy.module.css";
import Loader from "../Loader/Loader";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";

const OTTVoucher = () => {
  const OTTBaseURL = "https://test-api.otteswatini.com";
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const dataDec = dataReceiver;

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState([]);
  const [voucherAmount, setVoucherAmount] = useState("");
  const [uniqRef, setuniqRef] = useState("");

  useEffect(() => {
    const getPaymentMethods = async () => {
      setOpen(!open);

      const res = await axios.get(baseURL + "/bridgeBringPaymentPlatforms.php");

      const data = dataDec(res.data);
      setPaymentMethods(data);
      setOpen(false);
    };

    getPaymentMethods();
  }, []);

  const handleMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
  };

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const viewMyVouchers = () => {
    history.push({
      pathname: "/voucherDetails",
      state: { data: loginDetails },
    });
  };

  const purchaseOttVoucher = async (e) => {
    setOpen(!open);
    setMessage("");

    if (!voucherAmount || !selectedPaymentMethod) {
      setMessage("All input fields are required");
      setOpen(false);
      return;
    }

    let payload = {
      companies_id: loginDetails.organisations_id,
      mobileForSMS: loginDetails.momoAccountNumber,
      value: voucherAmount,
      paymentMethod: selectedPaymentMethod,
    };

    payload = JSON.stringify(payload);

    try {
      const response = await axios.post(
        baseURL + "bridgeReceiveOTTVoucherPurchase.php",
        payload
      );

      //const data = dataDec(response.data);
      const data = response.data;

      if (data.status == "200") {

	if(data.link){
		window.open(data.link, '_blank');

		history.push({
          		pathname: "/confirmvoucherpurchase",
          		state: { data: data },
        	});
	}else{
        	history.push({
          	pathname: "/confirmvoucherpurchase",
          	state: { data: data },
        	});
	}
      } else {
        setMessage(
          data.message
        );
      }
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong");
    } finally {
      setOpen(false);
    }
  };

  return (
    <div className={globa_styles.container + " " + payeasy_styles.container}>
      <h3 className={payeasy_styles.heading}>OTT Voucher on ePayNet</h3>

      <p className={payeasy_styles.paragraph}>
        Transact without a card, send and collect your money, or get paid online
        and then withdraw using the quick-and-easy cash solutions we designed
        for your financial empowerment. <br />
        <a target="_blank" href="https://ottvoucher.com/">
          Learn more
        </a>
      </p>

      <hr
        style={{
          display: "block",
          padding: "10px 0",
        }}
      />

      <Grid container direction="column">
        <Grid item container spacing={4}>
          <Grid item xs={12} sm={0} md={0} lg={2} />

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Button
              className={classes.loginButton}
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              style={{
                margin: "0 5px",
                padding: "20px 0",
                textTransaform: "none",
                backgroundColor: "#155054",
                color: "white",
                fontSize: "18px",
              }}
              onClick={() => viewMyVouchers()}>
              {" "}
              View My Vouchers
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body1">
                  <h5 style={{ marginBottom: "10px", fontSize: "18px" }}>
                    Buy New OTT Voucher
                  </h5>
                </Typography>
                {message && (
                  <p
                    style={{
                      margin: "3px 0",
                      color: "red",
                      textAlign: "center",
                      fontSize: "13px",
                    }}>
                    {message}
                  </p>
                )}

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Voucher Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Voucher Amount"
                    type="text"
                    id="voucherAmount"
                    name="voucherAmount"
                    onChange={(e) => setVoucherAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Choose payment method"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleMethodChange}
                    value={selectedPaymentMethod}>
                    {paymentMethods.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.description}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => purchaseOttVoucher()}>
                  {" "}
                  Buy OTT Voucher
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={0} md={0} lg={2} />
        </Grid>
      </Grid>
    </div>
  );
};

export default OTTVoucher;
