import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  NavBar,
  Footer,
  Pagination,
  CartItem,
  Login,
  Registration,
  LocalPayments,
  Dashboard,
  LenderActivation,
  Lending,
  LoanPayment,
  LoanSharing,
  BeneficiaryBankingDetails,
  ConfirmTransaction,
  BankTransactionStatus,
  History,
  ProofOfPayment,
  PasswordReset,
  MomoToATM,
  PayToCompany,
  ConfirmCompanyTransaction,
  AddRecipient,
  UserProfile,
  TransferLimit,
  Notifications,
  About,
  UserAccount,
  PrivacyPolicy,
  Help,
  PayEasy,
  OTTVoucher,
  ViewVoucher,
  ConfirmVoucherPurchase,
  ILoan,
  NotFound,
  LoanApplication,
  Activations,
  Balances,
} from "./components";
import { ConfirmActivation } from "./components/LenderActivation/ConfirmActivation/ConfirmActivation";
import { LenderActivationStatus } from "./components/LenderActivation/ConfirmActivation/LenderActivationStatus";
import { LendingStatus } from "./components/Lending/LendingStatus";
import { LoanHistory } from "./components/LoanPayment/LoanHistory";
import { ShareLoanContact } from "./components/LoanPayment/AddShareContact/ShareLoanContact";
import { ShareLoanStatus } from "./components/LoanPayment/AddShareContact/ShareLoanStatus";
import { LoanPaymentStatus } from "./components/LoanPayment/LoanPaymentStatus";
import { AddShareContact } from "./components/LoanSharing/AddShareContact";
import { BeneficiaryMomoDetails } from "./components/MomoToMomo/BeneficiaryMomoDetails";
import { ConfirmMomoTransaction } from "./components/MomoToMomo/ConfirmMomoTransaction";
import { BeneficiarysBank } from "./components/BankToMomo/BeneficiarysBank";
import { ConfirmATMTransaction } from "./components/MomoToATM/ConfirmATMTransaction";
import { PasswordCodeConfirmation } from "./components/PasswordReset/PasswordCodeConfirmation";
import { PasswordResetStatus } from "./components/PasswordReset/PasswordResetStatus";
import { TransactionHistory } from "./components/History/TransactionHistory/TransactionHistory";
import { ActivateEcommerce } from "./components/Ecommerce/ActivateEcommerce";
import { SchoolDetails } from "./components/School/SchoolDetails";
import { ConfirmSchoolTransaction } from "./components/School/ConfirmSchoolTransaction";
import { GiveDetails } from "./components/Give/GiveDetails";
import { ConfirmGiveTransaction } from "./components/Give/ConfirmGiveTransaction";
import { LocalTransactionStatus } from "./components/Give/LocalTransactionStatus";
import { UtilityDetails } from "./components/Utility/UtilityDetails";
import { ConfirmUtilityTransaction } from "./components/Utility/ConfirmUtilityTransaction";
import { Invoices } from "./components/Invoicing/Invoices";
import { Invoicing } from "./components/Invoicing/Invoicing";
import { Debts } from "./components/Invoicing/Debts";
import { SharedDebts } from "./components/Invoicing/SharedDebts";
import { PayInvoice } from "./components/Invoicing/PayInvoice";
import { InvoiceStatus } from "./components/Invoicing/InvoiceStatus";
import { UserDebtPayment } from "./components/Invoicing/UserDebtPayment";
import { InvoiceStatement } from "./components/Invoicing/InvoiceStatement";
import { AddInvoiceCredits } from "./components/Invoicing/AddInvoiceCredits";
import { Esacco } from "./components/Esacco/Esacco";
import { ConfirmEsacco } from "./components/Esacco/ConfirmEsacco";
import { EsaccoTransactionStatus } from "./components/Esacco/EsaccoTransactionStatus";
import { Saving } from "./components/Savings/Saving";
import { SavingsHistory } from "./components/Savings/SavingsHistory";
import { ConfirmSavings } from "./components/Savings/ConfirmSavings";
import { SavingPaymentStatus } from "./components/Savings/SavingPaymentStatus";
import { SavingsStatement } from "./components/Savings/SavingsStatement";
import { ServicesPayment } from "./components/ServicesPayment/ServicesPayment";
import { ConfirmService } from "./components/ServicesPayment/ConfirmService";
import { Insurance } from "./components/Insurance/Insurance";
import { ConfirmInsurancePayment } from "./components/Insurance/ConfirmInsurancePayment";
import { ActivateBusiness } from "./components/BusinessAccount/ActivateBusiness";
import { BusinessAccountDetails } from "./components/BusinessAccount/BusinessAccountDetails";
import { ActivateEpaynetBusiness } from "./components/BusinessAccount/ActivateEpaynetBusiness";
import { BusinessActivityHistory } from "./components/BusinessAccount/BusinessActivityHistory";
import { BusinessAccountActivity } from "./components/BusinessAccount/BusinessAccountActivities";
import { BusinessMembers } from "./components/BusinessAccount/BusinessMembers";
import { BusinessAuthentication } from "./components/BusinessAuthentication/BusinessAuthentication";
import { Requests } from "./components/Requests/Requests";
import { BusinessDocumentProcessing } from "./components/BusinessAccount/BusinessDocumentProcessing";
import { BusinessTransactions } from "./components/BusinessAccount/BusinessTransactions";
import { BusinessActivityResponse } from "./components/BusinessAccount/BusinessActivityResponse";
import { CartProvider } from "./Context/CartContext";
import { Authorization } from "./components/Authorization/Authorization";
import { UserVerification } from "./components/Authorization/UserVerification";

import { BusinessMenu } from "./components/BusinessAuthentication/BusinessMenu";
import { BusinessInvestment } from "./components/BusinessInvestment/BusinessInvestment";
import { InvestmentPlan } from "./components/BusinessInvestment/InvestmentPlan";
import { BusinessCommitments } from "./components/BusinessInvestment/BusinessCommitments";
import { RequestFunding } from "./components/BusinessRequest/RequestFunding";
import { BusinessApprovedRequests } from "./components/BusinessRequest/BusinessApprovedRequests";

import { ConfirmRequest } from "./components/BusinessRequest/ConfirmRequest";

import { ConfirmBidTransaction } from "./components/Requests/ConfirmBidTransaction";
import { ConfirmBidResponse } from "./components/Requests/ConfirmBidResponse";

import { Recharge } from "./components/Recharge/Recharge";
import { RechargeUnitsResponse } from "./components/Recharge/RechargeUnitsResponse";

import { EventHome } from "./components/CashlessEvents/EventHome";
import { EventsDashboard } from "./components/CashlessEvents/EventsDashboard";
import { BuyTicket } from "./components/CashlessEvents/BuyTicket/BuyTicket";
//import { TopUp } from './components/CashlessEvents/BuyTicket/TopUp';
import { PocketGenerate } from "./components/CashlessEvents/PocketFunding/PocketGenerate";
import { PocketGenerateView } from "./components/CashlessEvents/PocketFunding/PocketGenerateView";

import { PocketRecharge } from "./components/CashlessEvents/PocketFunding/PocketRecharge";
import { PocketRechargeStatus } from "./components/CashlessEvents/PocketFunding/PocketRechargeStatus";
import { RollBack } from "./components/CashlessEvents/PocketFunding/RollBack";
import { CheckBalance } from "./components/CashlessEvents/PocketFunding/CheckBalance";
import { ResetPin } from "./components/CashlessEvents/PocketFunding/ResetPin";
import { RollBackHistory } from "./components/CashlessEvents/PocketFunding/RollBackHistory";

import { Slider } from "./Layout/Slider/Slider";
import { BannerSlider } from "./Layout/Slider/BannerSlider";
import { HomePage } from "./Layout/HomePage";
import { GuestEventHome } from "./Layout/CashlessEventsGuest/GuestEventHome";
import { GuestPocketGenerate } from "./Layout/CashlessEventsGuest/PocketFunding/GuestPocketGenerate";
import { GuestPocketGenerateView } from "./Layout/CashlessEventsGuest/PocketFunding/GuestPocketGenerateView";
import { GuestEventsDashboard } from "./Layout/CashlessEventsGuest/GuestEventsDashboard";
import { GuestRollBack } from "./Layout/CashlessEventsGuest/PocketFunding/GuestRollBack";
import { GuestResetPin } from "./Layout/CashlessEventsGuest/PocketFunding/GuestResetPin";
import { CreateAccount } from "./Layout/ServiceAccount/CreateAccount";
import { RechargeServices } from "./Layout/ServiceAccount/RechargeServices";

import { Services } from "./components/Services/Services";
import { ServiceHistory } from "./components/Services/ServiceHistory";

import { Review } from "./components/Form/Review";
import { PaymentForm } from "./components/Form/PaymentForm";
import useStyles from "./components/Login/styles";

import { LoginAccountForm } from "./Layout/Forms/LoginAccountForm";
import { RegisterAccountForm } from "./Layout/Forms/RegisterAccountForm";
import LoansLandingPage from "./components/Loans/LoansLandingPage";
import Masibambisane from "./components/Masibambisane/Masibambisane";

import ILoanLandingPage from "./components/ILoan/iLoanLandingPage";
import Deposits from "./components/Deposits/Deposits";
import DepositStatus from "./components/Deposits/DepositStatus";
import Withdrawals from "./components/Withdrawals/Withdrawals";
import WithdrawalStatus from "./components/Withdrawals/WithdrawalStatus";

const App = () => {
  const classes = useStyles();

  return (
    <Router basename="/ePayNetWeb">
      <CartProvider>
        <div className={classes.content}>
          <NavBar />
          <Switch>
            <Route exact path="/">
              <Login />
            </Route>

            <Route exact path="/registration">
              <Registration />
            </Route>

            <Route exact path="/passwordreset">
              <PasswordReset />
            </Route>

            <Route exact path="/passwordcodeconfirmation">
              <PasswordCodeConfirmation />
            </Route>

            <Route exact path="/passwordresetstatus">
              <PasswordResetStatus />
            </Route>

            <Route exact path="/addrecipient">
              <AddRecipient />
            </Route>

            <Route exact path="/userprofile">
              <UserProfile />
            </Route>

            <Route exact path="/useraccount">
              <UserAccount />
            </Route>

            <Route exact path="/transferlimit">
              <TransferLimit />
            </Route>

            <Route exact path="/notifications">
              <Notifications />
            </Route>

            <Route exact path="/activateecommerce">
              <ActivateEcommerce />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            <Route exact path="/dashboard">
              <Dashboard />
            </Route>

            <Route exact path="/lenderactivation">
              <LenderActivation />
            </Route>

            <Route exact path="/lending">
              <Lending />
            </Route>

            <Route exact path="/loanpayment">
              <LoanPayment />
            </Route>

            <Route exact path="/loansharing">
              <LoanSharing />
            </Route>

            <Route exact path="/iloan">
              <ILoanLandingPage />
            </Route>

            <Route exact path="/deposits">
              <Deposits />
            </Route>

            <Route exact path="/activations">
              <Activations />
            </Route>

            <Route exact path="/balances">
              <Balances />
            </Route>

            <Route exact path="/depositStatus">
              <DepositStatus />
            </Route>

            <Route exact path="/loan-application">
              <LoanApplication />
            </Route>

            <Route exact path="/withdrawals">
              <Withdrawals />
            </Route>

            <Route exact path="/withdrawalStatus">
              <WithdrawalStatus />
            </Route>

            <Route exact path="/masibambisane">
              <Masibambisane />
            </Route>

            <Route exact path="/loanslandingpage">
              <LoansLandingPage />
            </Route>

            <Route exact path="/localpayments">
              <LocalPayments />
            </Route>

            <Route exact path="/confirmactivation">
              <ConfirmActivation />
            </Route>

            <Route exact path="/lenderactivationstatus">
              <LenderActivationStatus />
            </Route>

            <Route exact path="/lendingstatus">
              <LendingStatus />
            </Route>

            <Route exact path="/loanhistory">
              <LoanHistory />
            </Route>

            <Route exact path="/shareloancontact">
              <ShareLoanContact />
            </Route>

            <Route exact path="/shareloanstatus">
              <ShareLoanStatus />
            </Route>

            <Route exact path="/loanpaymentstatus">
              <LoanPaymentStatus />
            </Route>

            <Route exact path="/addsharecontact">
              <AddShareContact />
            </Route>

            <Route exact path="/beneficiarybankingdetails">
              <BeneficiaryBankingDetails />
            </Route>

            <Route exact path="/confirmtransaction">
              <ConfirmTransaction />
            </Route>

            <Route exact path="/banktransactionstatus">
              <BankTransactionStatus />
            </Route>

            <Route exact path="/beneficiarymomodetails">
              <BeneficiaryMomoDetails />
            </Route>

            <Route exact path="/confirmmomotransaction">
              <ConfirmMomoTransaction />
            </Route>

            <Route exact path="/confirmatmtransaction">
              <ConfirmATMTransaction />
            </Route>

            <Route exact path="/beneficiarysbank">
              <BeneficiarysBank />
            </Route>

            <Route exact path="/businessinvestment">
              <BusinessInvestment />
            </Route>

            <Route exact path="/businesscommitments">
              <BusinessCommitments />
            </Route>

            <Route exact path="/investmentplan">
              <InvestmentPlan />
            </Route>

            <Route exact path="/requests">
              <Requests />
            </Route>

            <Route exact path="/confirmbidtransaction">
              <ConfirmBidTransaction />
            </Route>

            <Route exact path="/confirmbidresponse">
              <ConfirmBidResponse />
            </Route>

            <Route exact path="/history">
              <History />
            </Route>

            <Route exact path="/transactionhistory">
              <TransactionHistory />
            </Route>

            <Route exact path="/proofofpayment">
              <ProofOfPayment />
            </Route>

            <Route exact path="/momotoatm">
              <MomoToATM />
            </Route>

            <Route exact path="/payeasy">
              <PayEasy />
            </Route>

            <Route exact path="/ottvoucher">
              <OTTVoucher />
            </Route>

            <Route exact path="/voucherDetails">
              <ViewVoucher />
            </Route>

            <Route exact path="/confirmvoucherpurchase">
              <ConfirmVoucherPurchase />
            </Route>

            <Route exact path="/paytocompany">
              <PayToCompany />
            </Route>

            <Route exact path="/confirmcompanytransaction">
              <ConfirmCompanyTransaction />
            </Route>

            <Route exact path="/schooldetails">
              <SchoolDetails />
            </Route>

            <Route exact path="/confirmschooltransaction">
              <ConfirmSchoolTransaction />
            </Route>

            <Route exact path="/givedetails">
              <GiveDetails />
            </Route>

            <Route exact path="/confirmgivetransaction">
              <ConfirmGiveTransaction />
            </Route>

            <Route exact path="/localtransactionstatus">
              <LocalTransactionStatus />
            </Route>

            <Route exact path="/utilitydetails">
              <UtilityDetails />
            </Route>

            <Route exact path="/confirmutilitytransaction">
              <ConfirmUtilityTransaction />
            </Route>

            <Route exact path="/invoices">
              <Invoices />
            </Route>

            <Route exact path="/invoicing">
              <Invoicing />
            </Route>

            <Route exact path="/debts">
              <Debts />
            </Route>

            <Route exact path="/shareddebts">
              <SharedDebts />
            </Route>

            <Route exact path="/payinvoice">
              <PayInvoice />
            </Route>

            <Route exact path="/invoicestatement">
              <InvoiceStatement />
            </Route>

            <Route exact path="/invoicestatus">
              <InvoiceStatus />
            </Route>

            <Route exact path="/userdebtpayment">
              <UserDebtPayment />
            </Route>

            <Route exact path="/addinvoicecredits">
              <AddInvoiceCredits />
            </Route>

            <Route exact path="/esacco">
              <Esacco />
            </Route>

            <Route exact path="/confirmesacco">
              <ConfirmEsacco />
            </Route>

            <Route exact path="/esaccotransactionstatus">
              <EsaccoTransactionStatus />
            </Route>

            <Route exact path="/saving">
              <Saving />
            </Route>

            <Route exact path="/savingshistory">
              <SavingsHistory />
            </Route>

            <Route exact path="/confirmsavings">
              <ConfirmSavings />
            </Route>

            <Route exact path="/savingpaymentstatus">
              <SavingPaymentStatus />
            </Route>

            <Route exact path="/savingsstatement">
              <SavingsStatement />
            </Route>

            <Route exact path="/servicespayment">
              <ServicesPayment />
            </Route>

            <Route exact path="/confirmservice">
              <ConfirmService />
            </Route>

            <Route exact path="/insurance">
              <Insurance />
            </Route>

            <Route exact path="/confirminsurancepayment">
              <ConfirmInsurancePayment />
            </Route>

            <Route exact path="/activatebusiness">
              <ActivateBusiness />
            </Route>

            <Route exact path="/businessaccountdetails">
              <BusinessAccountDetails />
            </Route>

            <Route exact path="/businessmenu">
              <BusinessMenu />
            </Route>

            <Route exact path="/activateepaynetbusiness">
              <ActivateEpaynetBusiness />
            </Route>

            <Route exact path="/businessactivityhistory">
              <BusinessActivityHistory />
            </Route>

            <Route exact path="/businessaccountactivity">
              <BusinessAccountActivity />
            </Route>

            <Route exact path="/businessmembers">
              <BusinessMembers />
            </Route>

            <Route exact path="/requestfunding">
              <RequestFunding />
            </Route>

            <Route exact path="/businessapprovedrequests">
              <BusinessApprovedRequests />
            </Route>

            <Route exact path="/confirmrequest">
              <ConfirmRequest />
            </Route>

            <Route exact path="/authorization">
              <Authorization />
            </Route>

            <Route exact path="/businessauthentication">
              <BusinessAuthentication />
            </Route>

            <Route exact path="/userverification">
              <UserVerification />
            </Route>

            <Route exact path="/requests">
              <Requests />
            </Route>

            <Route exact path="/businessdocumentprocessing">
              <BusinessDocumentProcessing />
            </Route>

            <Route exact path="/businesstransactions">
              <BusinessTransactions />
            </Route>

            <Route exact path="/businessactivityresponse">
              <BusinessActivityResponse />
            </Route>

            <Route exact path="/recharge">
              <Recharge />
            </Route>

            <Route exact path="/services">
              <Services />
            </Route>

            <Route exact path="/servicehistory">
              <ServiceHistory />
            </Route>

            <Route exact path="/rechargeunitsresponse">
              <RechargeUnitsResponse />
            </Route>

            <Route exact path="/privacypolicy">
              <PrivacyPolicy />
            </Route>

            <Route exact path="/help">
              <Help />
            </Route>

            <Route exact path="/buyticket">
              <BuyTicket />
            </Route>

            <Route exact path="/eventsdashboard">
              <EventsDashboard />
            </Route>

            <Route exact path="/eventhome">
              <EventHome />
            </Route>

            <Route exact path="/pocketgenerate">
              <PocketGenerate />
            </Route>

            <Route exact path="/pocketgenerateview">
              <PocketGenerateView />
            </Route>

            <Route exact path="/pocketrecharge">
              <PocketRecharge />
            </Route>

            <Route exact path="/pocketrechargestatus">
              <PocketRechargeStatus />
            </Route>

            <Route exact path="/rollback">
              <RollBack />
            </Route>

            <Route exact path="/rollbackhistory">
              <RollBackHistory />
            </Route>

            <Route exact path="/checkbalance">
              <CheckBalance />
            </Route>

            <Route exact path="/resetpin">
              <ResetPin />
            </Route>

            <Route exact path="/homepage">
              <HomePage />
            </Route>

            <Route exact path="/guesteventhome">
              <GuestEventHome />
            </Route>

            <Route exact path="/guestpocketgenerate">
              <GuestPocketGenerate />
            </Route>

            <Route exact path="/guestpocketgenerateview">
              <GuestPocketGenerateView />
            </Route>

            <Route exact path="/guesteventsdashboard">
              <GuestEventsDashboard />
            </Route>

            <Route exact path="/guestrollback">
              <GuestRollBack />
            </Route>

            <Route exact path="/guestresetpin">
              <GuestResetPin />
            </Route>

            <Route exact path="/createaccount">
              <CreateAccount />
            </Route>

            <Route exact path="/rechargeservices">
              <RechargeServices />
            </Route>

            <Route exact path="/loginaccountform">
              <LoginAccountForm />
            </Route>

            <Route exact path="/registeraccountform">
              <RegisterAccountForm />
            </Route>

            <Route exact path="/*">
              <Login />
            </Route>

            {/* <Route exact path="/*">
              <NotFound />
            </Route> */}
          </Switch>
          <Footer />
        </div>
      </CartProvider>
    </Router>
  );
};

export default App;
