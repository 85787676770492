import React, { useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

export const BeneficiarysBank = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginDetailsBag } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [paymentPlatforms, setPaymentPlatforms] = React.useState([]);
  const [selectedPaymentPlatform, setSelectedPaymentPlatform] = React.useState(
    []
  );
  let selectedPlatform = "";

  let banks = [
    {
      id: "1",
      name: "FNB",
      url: "https://www.fnbswaziland.co.sz/index.html",
    },
    {
      id: "2",
      name: "Nedbank",
      url: "https://secured.nedbank.co.sz/#/login",
    },
    {
      id: "3",
      name: "Standard Bank",
      url: "https://ebanking.standardbank.co.sz/#/Login",
    },
  ];

  useEffect(() => {
    const fetchPaymentPlatform = async () => {
      setPaymentPlatforms(banks);
    };

    fetchPaymentPlatform();
  }, []);

  const goToSelectedBank = () =>
    history.push({
      pathname: banks.url,
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentPlatform(event.target.value);
    selectedPlatform = event.target.value;
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          {/*
                        <Grid item xs={false} sm={4}/>            
                        <Grid item xs={12} sm={4}>
                      */}
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <CardContent>
                <Typography align="center" variant="body1">
                  <h5>Select Your Bank</h5>
                </Typography>{" "}
                <br></br>
                <Typography variant="body1" gutterBottom>
                  Please select your bank to redirect to your desired bank or
                  just login to your bank mobile app. For more info, open the
                  Awareness center on the side menu.
                </Typography>
                <br></br>
                <Typography variant="body1" gutterBottom></Typography>
                <Typography variant="body1" gutterBottom>
                  <TextField
                    label="Select Bank"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleChange}
                    value={selectedPaymentPlatform}>
                    {paymentPlatforms.map((option) => (
                      <MenuItem key={option.id} value={option.url}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => goToSelectedBank()}
                  href={selectedPaymentPlatform}
                  target="_blank">
                  <a href={selectedPaymentPlatform} target="_blank">
                    {" "}
                    Open
                  </a>
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default BeneficiarysBank;
