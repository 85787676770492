import React, { useEffect, useState, useContext } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { TextField, InputAdornment } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Backdrop from "@material-ui/core/Backdrop";
import Loader from "../Loader/Loader";

export const Insurance = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [companys, setCompanys] = useState([]);
  const [ESACCO, setESACCO] = useState([]);
  const [selectedEsacco, setSelectedEsacco] = useState([]);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [amount, setAmount] = React.useState([]);
  const [reference, setReference] = React.useState([]);
  let saccoMemberDetailsBag = {};
  let saccoPaymentDetailsBag = {};
  const [open, setOpen] = React.useState(false);
  const [selectedInsurer, setSelectedInsurer] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");
  const dataDec = dataReceiver;
  const [membershipNumber, setMembershipNumber] = useState("");
  const [selectedLender, setSelectedLender] = useState([]);
  let selectedInsurersID = "";

  useEffect(() => {
    setOpen(!open);

    const fetchSaccos = async () => {
      const res = await axios.get(baseURL + "bridgeBringInsurances.php");

      res.data = dataDec(res.data);

      setCompanys(res.data);
      setOpen(false);
    };

    fetchSaccos();
  }, []);

  const handleChange = (event) => {
    setSelectedInsurer(event.target.value);
    selectedInsurersID = event.target.value;

    for (let i = 0; i < companys.length; i++) {
      let companiesID = companys[i].id;

      if (selectedInsurersID == companiesID) {
        let companiesName = companys[i].mnemonic;
        setCompanyName(companiesName);
      }
    }
  };

  const handlerLenderChange = (event) => {
    setSelectedLender(event.target.value);
  };

  function checkMemberDetails() {
    setOpen(!open);

    let insurancePaymentDetailsBag = {
      insuranceID: selectedInsurer,
      companyID: loginDetails.organisations_id,
      membershipNumber: membershipNumber,
      amount: amount,
      reference: reference,
      insuranceCompanyName: companyName,
      policyNumber: policyNumber,
    };

    saccoMemberDetailsBag = insurancePaymentDetailsBag;
    goToMemberConfirmation(saccoPaymentDetailsBag);
    setOpen(false);
  }

  const goToMemberConfirmation = (saccoPaymentDetailsBag) =>
    history.push({
      pathname: "/confirminsurancepayment",
      insuranceMemberDetailsState: saccoMemberDetailsBag,
    });

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="center" variant="body1">
                  <h5>Transaction Details</h5>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Select Insurance Company"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleChange}
                    value={selectedInsurer}>
                    {companys.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.mnemonic}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Membership Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Membership Number"
                    type="text"
                    id="membershipNumber"
                    name="membershipNumber"
                    onChange={(e) => setMembershipNumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Policy Number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Policy Number"
                    type="text"
                    id="policyNumber"
                    name="policyNumber"
                    onChange={(e) => setPolicyNumber(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Amount"
                    type="text"
                    id="Amount"
                    name="Amount"
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Reference"
                    variant="outlined"
                    fullWidth
                    size="small"
                    placeholder="Reference"
                    type="text"
                    id="Reference"
                    name="Reference"
                    onChange={(e) => setReference(e.target.value)}
                  />
                </Typography>

                <Button
                  className={classes.loginButton}
                  type="button"
                  variant="contained"
                  fullWidth
                  color="primary"
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => checkMemberDetails()}>
                  {" "}
                  Submit
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default Insurance;
