import React, { useEffect, useState, useContext, useRef } from "react";
import { Card, CardContent, Typography, Grid, Button } from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CartContext } from "../../Context/CartContext";

import { slide } from "./HeaderSlides";
import styles from "../Dashboard/Dashboard.module.css";
import global_styles from "../../index.module.css";
import card_styles from "../ServiceCard/ServiceCard.module.css";
import { MyLocation } from "@mui/icons-material";
import ServiceCard from "../ServiceCard/ServiceCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper } from "@fortawesome/free-regular-svg-icons";
import {
  faAlignLeft,
  faBriefcase,
  faBuildingShield,
  faFileInvoice,
  faGraduationCap,
  faHeartPulse,
  faLayerGroup,
  faMoneyBill,
  faSatelliteDish,
  faMoneyBillTransfer,
  faArrowRight,
  faArrowLeft,
  faChevronRight,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";

const delay = 12000;

export const Dashboard = () => {
  const classes = useStyles();
  const history = useHistory();
  const {
    loginDetailsBag,
    accountsTypeBag,
    legalBusinessMemberAccountsBag,
    usersFeaturesBag,
  } = useContext(CartContext);
  const [legalBusinessMemberAccount, setLegalBusinessMemberAccount] =
    legalBusinessMemberAccountsBag;
  const [open, setOpen] = useState(false);
  const [userFeatureStatuses, setUserFeatureStatuses] = usersFeaturesBag;
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [accountsType, setAccountsType] = accountsTypeBag;
  const checkObjType = history.location.loginState;
  let isBusinessAccount = "";
  let userAccountType = "";
  let systemUsersRole = "";
  let p2pFunding = loginDetails.isLegalBusiness;
  let ePayNetBusiness = "";
  let onlineParticipant = "";
  let ePayNetUsersFeatureStatus = {};

  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === slide.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  if (Object.keys(userFeatureStatuses).length > 0) {
    p2pFunding = loginDetails.isCrowdFundingParticipant;
    ePayNetBusiness = loginDetails.isLegalBusiness;
    onlineParticipant = loginDetails.isEcommerceParticipant;

    ePayNetUsersFeatureStatus = {
      p2pFunding: loginDetails.isCrowdFundingParticipant,
      ePayNetBusiness: ePayNetBusiness,
      onlineParticipant: onlineParticipant,
    };
  } else {
    p2pFunding = loginDetails.isCrowdFundingParticipant;
    ePayNetBusiness = loginDetails.isLegalBusiness;
    onlineParticipant = loginDetails.isEcommerceParticipant;
    ePayNetUsersFeatureStatus = {
      p2pFunding: p2pFunding,
      ePayNetBusiness: ePayNetBusiness,
      onlineParticipant: onlineParticipant,
    };
  }

  useEffect(() => {
    setOpen(!open);
    setUserFeatureStatuses(ePayNetUsersFeatureStatus);
  }, []);

  let businessActive = Object.keys(legalBusinessMemberAccount).length;

  if (businessActive <= 0) {
    systemUsersRole = "0";
  } else if (businessActive > 0) {
    systemUsersRole = "1";
  }

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    console.log(checkObjType);
    setLoginDetails(checkObjType);

    let isBusinessAccount = checkObjType.isLegalBusiness;
    let p2pStatus = userFeatureStatuses.p2pFunding;

    console.log(p2pFunding);

    if (isBusinessAccount == "T") {
      if (p2pFunding == "T") {
        userAccountType = "businessAccount";
        setAccountsType(userAccountType);
      } else if (p2pFunding == "F") {
        userAccountType = "businessAccount";
        setAccountsType(userAccountType);
      }
    } else if (isBusinessAccount == "F") {
      if (p2pFunding == "T") {
        userAccountType = "businessAccount";
        setAccountsType(userAccountType);
      } else if (p2pFunding == "F") {
        userAccountType = "personalAccount";
        setAccountsType(userAccountType);
      }
    }
  }

  const localPayments = () => {
    history.push({
      pathname: "/LocalPayments",
    });
  };

  const openBusiness = () => {
    if (systemUsersRole == "0") {
      goToAuthenticate();
    } else if (systemUsersRole == "1") {
      goToDashboard();
    }
  };

  //pathname: '/businessauthentication'
  const goToAuthenticate = () =>
    history.push({
      pathname: "/businessmenu",
    });

  const goToDashboard = () =>
    history.push({
      pathname: "/businessaccountactivity",
    });

  const openLoans = () =>
    history.push({
      pathname: "/loanslandingpage",
    });

  const payToCompany = () =>
    history.push({
      pathname: "/paytocompany",
    });

  const schoolTransaction = () =>
    history.push({
      pathname: "/schooldetails",
    });

  const givingTransaction = () =>
    history.push({
      pathname: "/givedetails",
    });

  const openInvoicing = () =>
    history.push({
      pathname: "/invoices",
    });

  const utilityBillPayments = () =>
    history.push({
      pathname: "/utilitydetails",
    });

  const essacco = () =>
    history.push({
      pathname: "/esacco",
    });

  const insurance = () =>
    history.push({
      pathname: "/insurance",
    });

  const opendstv = () =>
    history.push({
      pathname: "/services",
    });

  const openPayEasy = () =>
    history.push({
      pathname: "/payeasy",
    });

  const openOTT = () =>
    history.push({
      pathname: "/ottvoucher",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar}>
        <div className={styles.showcase}>
          <div
            style={{ paddingTop: "20px" }}
            className={styles.username + " " + global_styles.container}>
            <p>Welcome, {loginDetails.fullName}</p>
          </div>

          <div
            className={
              styles.slideshow__container + " " + global_styles.container
            }>
            <div
              className={styles.showcase__slider}
              style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
              {slide.map((slide, index) => {
                return (
                  <div key={index} className={styles.slides}>
                    <div className={styles.contents}>
                      <h2>{slide.heading}</h2>
                      <p className={styles.copy}>{slide.copy}</p>
                      <a
                        target="_blank"
                        className={styles.link}
                        href={slide.url}>
                        Learn More
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className={styles.icon}
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={styles.arrows}>
              <div
                className={index <= 0 ? styles.hide : styles.arrowLeft}
                onClick={() => setIndex(index - 1)}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>

              <div
                className={
                  index > slide.length - 1 ? styles.hide : styles.arrowRight
                }
                onClick={() => setIndex(index + 1)}>
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
            <div className={styles.slideshow__dots}>
              {slide.map((_, idx) => (
                <div
                  key={idx}
                  className={
                    index === idx
                      ? styles.dot + " " + styles.active
                      : styles.dot
                  }
                  onClick={() => setIndex(idx)}></div>
              ))}
            </div>
          </div>
        </div>

        <div className={styles.service__cards + " " + global_styles.container}>
          {accountsType == "businessAccount" && (
            <ServiceCard
              onClick={() => openBusiness()}
              title={"Business Account"}
              icon={
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className={card_styles.card__icon}
                />
              }
            />
          )}

          <ServiceCard
            onClick={() => localPayments()}
            title={"Local Payments"}
            icon={<MyLocation className={card_styles.card__icon} />}
          />
          <ServiceCard
            onClick={() => openOTT()}
            title={"OTT Voucher"}
            icon={
              <FontAwesomeIcon
                icon={faTicket}
                className={card_styles.card__icon}
              />
            }
          />
	  <ServiceCard
            onClick={() => opendstv()}
            title={"DStv"}
            icon={
              <FontAwesomeIcon
                icon={faSatelliteDish}
                className={card_styles.card__icon}
              />
            }
          />
	  <ServiceCard
            onClick={() => openLoans()}
            title={"Loans"}
            icon={
              <FontAwesomeIcon
                icon={faAlignLeft}
                className={card_styles.card__icon}
              />
            }
          />
	  <ServiceCard
            onClick={() => insurance()}
            title={"Insurance"}
            icon={
              <FontAwesomeIcon
                icon={faHeartPulse}
                className={card_styles.card__icon}
              />
            }
          />          
          <ServiceCard
            onClick={() => openPayEasy()}
            title={"PayEasy"}
            icon={
              <FontAwesomeIcon
                icon={faMoneyBillTransfer}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => payToCompany()}
            title={"Pay to Company"}
            icon={
              <FontAwesomeIcon
                icon={faNewspaper}
                className={card_styles.card__icon}
              />
            }
          />          
          <ServiceCard
            onClick={() => openInvoicing()}
            title={"Invoice Manager"}
            icon={
              <FontAwesomeIcon
                icon={faFileInvoice}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => schoolTransaction()}
            title={"Schools"}
            icon={
              <FontAwesomeIcon
                icon={faGraduationCap}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => givingTransaction()}
            title={"Give"}
            icon={
              <FontAwesomeIcon
                icon={faMoneyBill}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => utilityBillPayments()}
            title={"Utilities"}
            icon={
              <FontAwesomeIcon
                icon={faLayerGroup}
                className={card_styles.card__icon}
              />
            }
          />
          <ServiceCard
            onClick={() => essacco()}
            title={"SACCOs"}
            icon={
              <FontAwesomeIcon
                icon={faBuildingShield}
                className={card_styles.card__icon}
              />
            }
          />          
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
