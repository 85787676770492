import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Backdrop,
} from "@material-ui/core";
import globa_styles from "../../index.module.css";
import payeasy_styles from "../PayEasy/PayEasy.module.css";
import Loader from "../Loader/Loader";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

const ViewVoucher = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver } = useContext(CartContext);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const dataDec = dataReceiver;

  const [userVouchers, setUserVouchers] = useState([]);

  const checkObjType = history.location.loginState;

  if (
    typeof checkObjType !== "undefined" &&
    Object.keys(history.location.loginState).length > 0
  ) {
    setLoginDetails(history.location.loginState);
    history.push({ pathname: "/", state: {} });
  }

  const getUserVouchers = async (e) => {
    setOpen(!open);

    let payload = {
      companies_id: loginDetails.organisations_id,
    };

    payload = JSON.stringify(payload);

    try {
      const response = await axios.post(
        baseURL + "bridgeGetMyOTTVoucher.php",
        payload
      );

      const data = dataDec(response.data);
      console.log(data);
      setUserVouchers(data);
    } catch (error) {
      console.log(error);
      setMessage("Something went wrong");
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    getUserVouchers();
  }, []);

  return (
    <div className={globa_styles.container + " " + payeasy_styles.container}>
      <h3 className={payeasy_styles.heading}>Your OTT Vouchers</h3>

      <p className={payeasy_styles.paragraph}>
        Transact without a card, send and collect your money, or get paid online
        and then withdraw using the quick-and-easy cash solutions we designed
        for your financial empowerment. <br />
        <a target="_blank" href="https://ottvoucher.com/">
          Learn more
        </a>
      </p>

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={1} md={2} lg={2} />

          <Grid item xs={12} sm={10} md={8} lg={8}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardContent>
                <Typography align="left" variant="body1">
                  <h5>
                    Viewing vouchers for:{" "}
                    <strong> {loginDetails.fullName}</strong>
                  </h5>
                  <p>
                    Purchased using cell:{" "}
                    <strong> {loginDetails.momoAccountNumber} </strong>
                  </p>
                </Typography>

                <hr />
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead style={{ background: "#245054" }}>
                        <TableRow>
                          
                          <TableCell
                            align="left"
                            sx={{ color: "white", fontWeight: "bold" }}>
                            Voucher No.
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: "white", fontWeight: "bold" }}>
                            Voucher PIN
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: "white", fontWeight: "bold" }}>
                            Amount
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{ color: "white", fontWeight: "bold" }}>
                            Transac. ID
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userVouchers.length > 0 ? (
                          userVouchers.map((voucher, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}>
                              
                              <TableCell align="left">
                                <Tooltip
                                  title={voucher.serialNumber}
                                  placement="bottom">
                                  <span
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "120px", // Set max width to show truncation
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}>
                                    {voucher.serialNumber}
                                  </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">{voucher.PIN}</TableCell>
                              <TableCell align="left">
                                E {voucher.amount}
                              </TableCell>
                              <TableCell align="left">
                                <Tooltip
                                  title={voucher.transactionNumber}
                                  placement="bottom">
                                  <span
                                    style={{
                                      display: "inline-block",
                                      maxWidth: "130px", // Set max width to show truncation
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}>
                                    {voucher.transactionNumber}
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              No vouchers available
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={false} sm={1} md={2} lg={2} />
        </Grid>
      </Grid>
    </div>
  );
};

export default ViewVoucher;
