import React, { useEffect, useContext, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  MenuItem,
} from "@material-ui/core";
import useStyles from "../Login/styles";
import { useHistory } from "react-router-dom";
import { CardHeader, TextField } from "@material-ui/core";
import { CartContext } from "../../Context/CartContext";
import axios from "axios";
import Loader from "../Loader/Loader";
import Backdrop from "@material-ui/core/Backdrop";

export const ActivateEpaynetBusiness = () => {
  const classes = useStyles();
  const history = useHistory();
  const { baseURL, loginDetailsBag, dataReceiver, p2pFundingDetailsBag } =
    useContext(CartContext);
  const [p2pFundingDetails, setP2pFundingDetails] = p2pFundingDetailsBag;
  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [loginDetails, setLoginDetails] = loginDetailsBag;
  const [activeStatus, setActiveStatus] = useState([]);
  const [moduleStatus, setModuleStatus] = useState("");
  const [moduleAPIindex, setModuleAPIindex] = useState("");
  let appUserID = loginDetails.organisations_id;
  let selectedConsent = "";
  let status = "";
  let featureID = "";
  let featuresName = "";

  const [selectedFeature, setSelectedFeature] = useState("");
  const [featureName, setFeatureName] = useState("");
  const [activationCategory, setActivationCategory] = useState([
    { id: "3", name: "ePayNet P2P eFunding" },
    { id: "1", name: "ePayNet Business" },
    { id: "2", name: "Online checkout" },
  ]);
  let mySelectedFeature = "";
  const dataDec = dataReceiver;
  const consent = [
    { id: 1, name: "Yes", value: "T" },
    { id: 2, name: "No", value: "F" },
  ];

  const updateUserLogin = () => {
    /*    
           const updateObjectInArray = () => {
              setEmployees(current =>
                current.map(obj => {
                  if (loginDetails.id === 2) {
                    return {...obj, name: 'Sophia', country: 'Sweden'};
                  }
                  return obj;
                }),
              );
            };
    */
  };

  const handleConsentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(event.target.value);
    selectedConsent = event.target.value;
  };

  const handleFeatureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModuleStatus("");
    setSelectedFeature(event.target.value);
    mySelectedFeature = event.target.value;

    if (mySelectedFeature == "1") {
      activateEpayNetBusiness();
    }

    if (mySelectedFeature == "2") {
      activateOnlineCheckout();
    }

    if (mySelectedFeature == "3") {
      activateCrowdFunding();
    }

    for (let i = 0; i < activationCategory.length; i++) {
      featureID = activationCategory[i].id;

      if (featureID == mySelectedFeature) {
        featuresName = activationCategory[i].name;
        setFeatureName(featuresName);
      }
    }
  };

  const activateEpayNetBusiness = () => {
    let eCommerceStatus = loginDetails.isLegalBusiness;

    if (eCommerceStatus === "T") {
      status = "Active";
    } else if (eCommerceStatus === "F") {
      status = "Inactive";
    }

    setModuleStatus(status);
    setModuleAPIindex("bridgeReceiveEpayNetWebAccountAction");
  };

  const activateCrowdFunding = async () => {
    setOpen(!open);
    let ePayNetID = loginDetails.organisations_id;
    let loggedInUsersID = loginDetails.companyUID;
    let data = {
      ePayNetCompanyID: ePayNetID,
      ePayNetLoggedInUserID: loggedInUsersID,
    };
    setModuleAPIindex("bridge_iFund_ChangeMyCrowdFundingProfileStatus");

    const res = await axios
      .post(
        baseURL + "/bridge_iFund_ePayNetCompanyCrowdFundingStatus.php",
        data
      )
      .then(function (response) {
        response.data = dataDec(response.data);
        let eCommerceStatus = response.data[0].isCrowdFundingParticipant;
        let crowdFundingDetails = response.data;
        setP2pFundingDetails(crowdFundingDetails[0]);

        if (eCommerceStatus === "T") {
          status = "Active";
        } else if (eCommerceStatus === "F") {
          status = "Inactive";
        }

        setModuleStatus(status);
        setOpen(false);
      });
  };

  const activateOnlineCheckout = () => {
    let eCommerceStatus = loginDetails.isEcommerceParticipant;

    if (eCommerceStatus === "Y") {
      status = "Active";
    } else if (eCommerceStatus !== "Y") {
      status = "Inactive";
    }

    setModuleStatus(status);
    setModuleAPIindex("bridgeOnlineParticiapationStatus");
  };

  const updateePayNetStatus = async () => {
    setOpen(!open);
    let companyID = loginDetails.organisations_id;
    let usersID = loginDetails.companyUID;
    let username = loginDetails.username;
    let action = selectedStatus;
    let data = {};

    if (moduleAPIindex == "bridgeReceiveEpayNetWebAccountAction") {
      data = {
        action: selectedStatus,
        companyID: companyID,
        username: username,
      };
    }

    if (moduleAPIindex == "bridgeOnlineParticiapationStatus") {
      let myOnlineParticipationUpdate = "";

      if (selectedStatus == "T") {
        myOnlineParticipationUpdate = "Y";
      } else {
        myOnlineParticipationUpdate = "N";
      }

      data = {
        isEcommerceParticipant: myOnlineParticipationUpdate,
        logonCompanies_id: companyID,
      };
    }

    if (moduleAPIindex == "bridge_iFund_ChangeMyCrowdFundingProfileStatus") {
      data = {
        ePayNetCompanyID: companyID,
        ePayNetLoggedInUserID: usersID,
        crowdFundingStatusRequest: action,
      };
    }

    const res = await axios
      .post(baseURL + moduleAPIindex + ".php", data)
      .then(function (response) {
        response.data = dataDec(response.data);

        if (response.data.code == 200) {
          setActiveStatus(response.data);
          setOpen(false);
        } else {
          setActiveStatus(response.data);
          setOpen(false);
        }
      });
  };

  const goToTransactionConfirmation = () =>
    history.push({
      pathname: "/confirmmomotransaction",
    });

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />

      <Grid container direction="column">
        <Grid item></Grid>

        <Grid item container spacing={4}>
          <Grid item xs={false} sm={2} md={3} lg={4} />

          <Grid item xs={12} sm={8} md={6} lg={4}>
            <Card>
              <Backdrop className={classes.backdrop} open={open}>
                <Loader />
              </Backdrop>

              <CardHeader title="Activate ePayNet Features" align="center" />
              <CardContent>
                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Select feature to activate"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleFeatureChange}
                    value={selectedFeature}>
                    {activationCategory.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                <Typography variant="h4" gutterBottom>
                  <TextField
                    label="Choose Option"
                    variant="outlined"
                    fullWidth
                    size="small"
                    select
                    onChange={handleConsentChange}
                    value={selectedStatus}>
                    {consent.map((option) => (
                      <MenuItem key={option.id} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>

                {featureName ? (
                  <>
                    <Typography variant="body2" gutterBottom>
                      {featureName} status :<b>{moduleStatus}</b>
                    </Typography>
                    <br />

                    <Typography variant="body2" gutterBottom>
                      Please choose option 'YES' and click submit to activate{" "}
                      <b>{featureName}</b>. This will enable you to process bulk
                      payments to Mobile Money.
                    </Typography>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                <Button
                  className={classes.button}
                  type="button"
                  variant="contained"
                  fullWidth
                  style={{
                    margin: "5px",
                    textTransaform: "none",
                    backgroundColor: "#155054",
                    color: "white",
                  }}
                  onClick={() => updateePayNetStatus()}>
                  Update
                </Button>

                <br />
                {activeStatus.description !== "" && (
                  <Typography
                    variant="body2"
                    gutterBottom
                    activeStatus={activeStatus}>
                    <b> {activeStatus.description} </b>
                  </Typography>
                )}
              </CardContent>
              <br />
            </Card>
          </Grid>

          <Grid item xs={false} sm={2} md={3} lg={4} />
        </Grid>
      </Grid>
    </main>
  );
};

export default ActivateEpaynetBusiness;
