import React from "react";
import globa_styles from "../../index.module.css";
import styles from "../Dashboard/Dashboard.module.css";
import payeasy_styles from "../PayEasy/PayEasy.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";

const PayEasy = () => {
  return (
    <div className={globa_styles.container + " " + payeasy_styles.container}>
      <h3 className={payeasy_styles.heading}>PayEasy</h3>

      <p className={payeasy_styles.paragraph}>
        PayEasy is a Fintech driven solution for the Eswatini payment space to
        enhance loan servicing and repayment collection for all service
        providers through source deductions... <br />
        <a
          target="_blank"
          href="http://salvtec.co.sz/support/knowledgebase.php?article=27"
        >
          Learn more
        </a>
      </p>

      <p className={payeasy_styles.marketplace}>
        Financial services and credit service providers can also get listed on
        the PayEasy <strong>MarketPlace</strong> to get exposure to a wider
        community of customers. <br />

 	<a          
          className={styles.link}
          href="https://marketplace.salvsystems.com" target="_blank">
          Go to Marketplace
          <FontAwesomeIcon icon={faExternalLink} className={styles.icon} />
        </a>
      </p>
    </div>
  );
};

export default PayEasy;
